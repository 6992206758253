<template>
    <div>
        <router-link class="v-back" to="/testing/settings/">
            <i class="v-back__icon"></i>
            Назад
        </router-link>
        <h2 class="content-heading">Результаты тестирования</h2>
        <div class="testing-results__tabs v-tabs">
            <div
                class="testing-results__tab v-tab"
                :class="{'testing-results__tab--active':currentTab === 1}"
                @click="currentTab = 1"
            >
                <h3 class="h3 testing-results__title">Все тесты {{results.length}}</h3>
            </div>
            <div
                class="testing-results__tab"
                @click="currentTab = 2"
                :class="{'testing-results__tab--active':currentTab === 2}"
            >
                <h3 class="h3 testing-results__title">Проверка ответов {{ answersList.count }}</h3>
            </div>
        </div>
        <div class="block" v-if="currentTab === 1">
            <div class="block-content">
                <div class="row">
                    <div class="col-sm-12" style="overflow-x: auto">
                        <div class="content content-full" v-if="results.length === 0">
                            <div class="py-30 text-center">
                                <h1 class="h2 font-w700 mt-30 mb-10">В этом разделе будут показываться результаты тестирования.</h1>
                                <h2 class="h3 font-w400 text-muted mb-50">Сейчас у вас нет сотрудников, проходящих тестирование.</h2>
                            </div>
                        </div>
                        <table class="table table-striped table-vcenter" v-else>
                            <thead>
                            <tr>
                                <th class="text-center" style="width: 100px;"><i class="si si-user"></i></th>
                                <th>ФИО</th>
                                <th class="text-center" style="width: 25%;">Статус тестирования</th>
                                <th class="text-center" style="width: 25%;">Дата тестирования</th>
                                <th class="text-center" style="width: 25%;" v-for="column in columns" :key="`column_${column}`">{{column}}</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in results" :key="`result_${item.id}`">
                                    <td class="text-center">
                                        <img
                                            class="img-avatar img-avatar48"
                                            :src="item.user.image_zoom_out || defaultAvatar"
                                            loading="lazy"
                                            @error="errorAvatar"
                                        >
                                    </td>
                                    <td class="font-w600">
                                        <router-link :to="`/auth/profile/${item.user.id}/`">{{ item.user.full_name }}</router-link>
                                    </td>
                                    <td class="text-center">
                                        <span class="badge badge-success" v-if="item.is_passed">Пройдено</span>
                                        <span class="badge badge-danger" v-else>
                                            Не пройдено
                                        </span>
                                    </td>
                                    <td class="text-center">
                                      {{item.date_passing}}
                                    </td>
                                    <td class="text-center" v-for="(k_b, index) in item.knowledge_block" :key="`k_b_${index}`">
                                        <span class="badge badge-success" v-if="k_b.correct && k_b.exists">Пройдено</span>
                                        <span class="badge badge-danger" v-else-if="!k_b.correct && k_b.exists">
                                            Не пройдено
                                        </span>
                                        <span class="badge badge-danger" v-else-if="!k_b.exists">
                                            Не участвует
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <loading-spinner
                                v-if="isLoading"
                                class="wall__spinner"
                            />
                            <mugen-scroll
                                :handler="infiniteScrollHandler"
                                :should-handle="!isLoading"
                            />
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="block" v-else>
            <div class="block-content">
                <div class="row">
                    <div class="col-sm-12" style="overflow-x: auto">
                        <div class="content content-full" v-if="answersList.results.length === 0">
                            <div class="py-30 text-center">
                                <h1 class="h2 font-w700 mt-30 mb-10">В этом разделе будут показываться ответы для проверки.</h1>
                                <h2 class="h3 font-w400 text-muted mb-50">Сейчас у вас нет ответов для проверки .</h2>
                            </div>
                        </div>
                        <table class="table table-striped table-vcenter mb-0" v-else>
                            <thead>
                            <tr>
                                <th class="text-center" style="width: 100px;"><i class="si si-user"></i></th>
                                <th>ФИО</th>
                                <th class="text-center" style="width: 25%;">Дата тестирования</th>
                                <th class="text-center" style="width: 25%;">Вопрос</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="answer in answersList.results" :key="`answer_${answer.id}`">
                                <td class="text-center">
                                    <img class="img-avatar img-avatar48" :src="answer.avatar || '/static/media/avatars/avatar15.jpg/'" alt="">
                                </td>
                                <td class="font-w600">
                                    <router-link :to="`/auth/profile/${answer.user_id}/`">{{ answer.fio }}</router-link>
                                </td>
                                <td class="text-center">
                                    {{answer.date }}

                                </td>
                                <td class="text-center">
                                    {{ answer.question }}
                                </td>
                                <td class="text-center">
                                    <button @click="openCheckModal(answer)" class="v-btn">
                                        Проверить
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <loading-spinner
                                v-if="isLoading"
                                class="wall__spinner"
                            />
                            <mugen-scroll
                                :handler="infiniteScrollHandler"
                                :should-handle="!isLoading"
                            />
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session';
    import MugenScroll from 'vue-mugen-scroll';
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "TestResults",
        components: {
            MugenScroll,
            LoadingSpinner : () => import('@/components/LoadingSpinner')
        },
        data() {
            return {
                nextData: '/api/v1/testing-result/',
                results: [],
                columns: [],
                isLoading: false,
                currentTab: 1,
                answersList: [],
                defaultAvatar
            }
        },
        async created() {
            await this.loadList();
            try {
                const request = session.get('/api/v1/testing-result/default_form/');
                const response = await request;
                this.columns = response.data.columns;

                this.loadAnswersList();
            } catch(error) {
                console.error(error)
            }
        },
        methods: {
            openCheckModal(answer) {
                const modal = () => import(`@/components/test/modals/AnswerCheckModal`);
                this.$modal.show(modal, {
                    answer
                }, {
                    name: 'AnswerCheckModal',
                    adaptive: true,
                    maxWidth: 500,
                    width: '100%',
                    height: 'auto'
                    }, {
                        'before-close': this.resetPage
                    }
                )
            },
            resetPage(data) {
                if (data.params) {
                    let indexOf = this.answersList.results.indexOf(data.params.item);
                    this.answersList.count -= 1;
                    this.answersList.results.splice(indexOf, 1);
                    this.results = [];
                    this.nextData = '/api/v1/testing-result/';
                    this.loadList();
                }
            },
            async infiniteScrollHandler() {
                try {
                    if (!this.nextData) {
                        return false;
                    }
                    this.isLoading = true;

                    clearTimeout(this.timer);
                    this.timer = setTimeout(async () => {
                        await this.loadList();
                        this.isLoading = false
                    }, 500);

                } catch(error) {
                    console.error(error);
                    this.isLoading = false;
                }
            },
            async loadList() {
                try {

                    const request = session.get(this.nextData);
                    const response = await request;

                    this.results.push(...response.data.results);
                    this.nextData = response.data.next;

                } catch(error) {
                    console.error(error)
                }
            },
            async loadAnswersList() {
                try {
                    const request = await session.get(`/api/v1/testing-answer-wait/`);
                    this.answersList = request.data;
                } catch (error) {
                    console.error(error)
                }
            },
            errorAvatar(event) {
                event.target.setAttribute('src', this.defaultAvatar);
            }
        }
    }
</script>
<style lang="sass">
@import "#sass/v-style"
.testing-results
    &__tabs
        display: flex
        position: relative
    &__tab
        display: flex
        cursor: pointer
        position: relative
        height: 100%
        & > .h3
            color: $text-gray
        &--active
            &::before
                content: ''
                position: absolute
                height: 3px
                width: 100%
                bottom: -15px
                left: 0
                background: $gold
            & > .h3
                color: #000
        &:first-child
            margin-right: 30px
    &__title
        font-size: 0.875rem
        text-transform: uppercase
        margin-bottom: 0
        letter-spacing: 0.06em


</style>
